
import React from "react"

import CourseForm from "../../components/negosyonow/forms/courseform";

const basicFormFields = [
	//
	{"label":"Presentor", "field": "nncourse_presenter", "value": "", "input": "text", "mode": "normal", "info":"Custom 'Presented by' name, but it removes link to organization page"},
	{"label":"Page Banner", "field": "nncourse_banner", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":1200, "imageheight":480, "imageformat":"jpg", "uploadtype": "https","filehost": "cdn.ngnw.ph", "filelocation":"images/course/banner"},
	{"label":"Listing Image", "field": "nncourse_bannermobile", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":1024, "imageheight":1024, "imageformat":"jpg", "uploadtype": "https","filehost": "cdn.ngnw.ph", "filelocation":"images/course/bannermobile"},
];


const detailsFormFields = [
	//
	{"label":"Teaser Video", "field": "nncourse_videourl", "value": "", "input": "videourl", "mode": "normal"},

	{"label":"Summary", "field": "nncourse_summary", "value": "", "input": "textarea", "mode": "required", "maxlen":250},
	{"label":"Description", "field": "nncourse_description", "value": "", "input": "richtextarea", "mode": "required"},
];


const presentationFormFields = [
	{"label":"Registration Validity", "field": "nncourse_validitydays", "value": "", "input": "integer", "mode": "required", "info":"Changing to validity will not affect existing registrants"},
	{"label":"Registration Fee", "field": "nncourse_regfee", "value": "", "input": "currency", "mode": "required"},
	{"label":"Payment Instructions", "field": "nncourse_paymentinstructions", "value": "", "input": "textarea", "mode": "normal", "info": "Displayed after registration, only when there are fees"},
];


const subformFields = [
	{
		"subformid": "nncoursecontent",
		"label": "Content",
		"table": "nncoursecontent",
		"sort": "nncoursecontent_order",
		"mobilerowtitlefieldidx":[1],
		"minrow": 0,
		"maxrow": 5,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Order",
				"field": "nncoursecontent_order",
				"subtotalid": "",
				"value": "",
				"input": "integer",
				"mode": "required"
			},
			{
				"label": "Title",
				"field": "nncoursecontent_name",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "required",
				"info": "leave video and file blank for headings"
			},
			{
				"label": "Thumbnail",
				"field": "nncoursecontent_thumbnail",
				"subtotalid": "",
				"value": "",
				"input": "image",
				"imageformat":"jpg",
				"mode": "normal",
				"uploadtype": "https",
				"filehost": "cdn.ngnw.ph",
				"filelocation": "images/course/content/thumbnails",
				"imagemode": "fit",
				"imagewidth": 256,
				"imageheight": 256,

			},
			{
				"label": "Video URL",
				"field": "nncoursecontent_videourl",
				"subtotalid": "",
				"value": "",
				"input": "videourl",
				"mode": "normal",
			},
			{
				"label": "File/Image",
				"field": "nncoursecontent_url",
				"subtotalid": "",
				"value": "",
				"mode": "normal",
				"filehost": "private.cdn.ngnw.ph",
				"filelocation": "file/course/content",

				"uploadtype": "https",
				"input": "file",
/*
				"uploadtype": "webapp",
				"input": "flexibleimage",
				"imageformat":"jpg",
				"imagemode": "limit",
				"imagewidth": 2048,
				"imageheight": 2048,
*/
			},

			{
				"label": "Duration (secs)",
				"field": "nncoursecontent_durationsecs",
				"subtotalid": "totalmins",
				"destfield": "nncourse_totalsecs",
				"value": "",
				"input": "integer",
				"mode": "normal",
			},
		],
		"footerlist": [
		]
	},
];


const CoursePage = ({location}) => {

	return <CourseForm
				location={location}
				allowadd={true}
				allowdelete={false}
				customeditformfields={basicFormFields.concat(detailsFormFields).concat(presentationFormFields)}
				customnewformfields={basicFormFields.concat(presentationFormFields)}
				editSubFormFields={subformFields}
				newSubformFields={[]}
			/>


}


export default CoursePage;
