
import React, {useState} from "react"

import Layout from "../../layout"
import WebappsForm from "../../webapps/form"

const negosyonowAPI = require("../../../../lib/requestNegosyownow")
const webappsAPI = require("../../../../lib/requestWebapps");


const ENTITYID="nncourse"


const seriespickerfields = [
	{"label":"Name", "dbfield": "nncourseseries_name", "type": "text", "filtertype": "textbox"},
	{"label":"Summary", "dbfield": "nncourseseries_summary", "type": "text", "filtertype": "textbox"}
];

const seriespickerfilter = [];


const searchFields = [
	{"label":"Series", "dbfield": "nncourseseries_name", "type": "text", "filtertype": "text"},
	{"label":"Title", "dbfield": "nncourse_name", "type": "text", "filtertype": "text"},
	{"label":"Page Banner", "dbfield": "nncourse_banner", "type": "image", "filtertype": "image"},
	{"label":"Listing Image", "dbfield": "nncourse_bannermobile", "type": "image", "filtertype": "image"},
	{"label":"Summary", "dbfield": "nncourse_summary", "type": "text", "filtertype": "text"},
	{"label":"Duration (secs)", "dbfield": "nncourse_totalsecs", "type": "integer", "filtertype": "integer"},
	{"label":"Validity (days)", "dbfield": "nncourse_validitydays", "type": "integer", "filtertype": "integer"},
];

const formFields = [
	[
		{"label":"Date Created", "field": "nncourse_created", "value": "", "input": "createtime", "mode": "readonly"},
		{"label":"Date Updated", "field": "nncourse_updated", "value": "", "input": "updatetime", "mode": "readonly"},
		{"label":"Series", "field": "nncourseseries_name", "value": "", "input": "picker", "mode": "readonly"},
		{"label":"Title", "field": "nncourse_name", "value": "", "input": "textbox", "mode": "unique"},
		{"label":"Has Page", "field": "nncourse_haspage", "value": "", "input": "hidden", "mode": "readonly"},
		{"label":"Webpage", "field": "nncourse_url", "value": "", "input": "url", "mode": "readonly", "urlprefix":"https://negosyonow.com/course/"},
	]
];


const newFormFields = [
	[
		{"label":"Date Created", "field": "nncourse_created", "value": "", "input": "createtime", "mode": "readonly"},
		{"label":"Date Updated", "field": "nncourse_updated", "value": "", "input": "updatetime", "mode": "readonly"},
		{"label":"Series Name", "field": "nncourseseries_name", "value": "", "input": "picker", "mode": "required", "pickerfields": seriespickerfields, "pickerfilters": seriespickerfilter},
		{"label":"Title", "field": "nncourse_name", "value": "", "input": "textbox", "mode": "unique"},
	]
];



const CourseForm = ({location, allowadd, allowdelete, customnewformfields, customeditformfields, editSubFormFields, newSubformFields}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;

		if (params.hasOwnProperty("documentid")) {
			if (params.documentid) {
				pkid = params.documentid;
			}
		}
		if (pkid === 0) {
			params.nncourseq_id=3;	// Basic Questionaire
			if (params.hasOwnProperty("nncourseseries_id")) {
				const apiparam = {
					"dbfieldlist":["onsbranch_id", "nncourseseries_name",
									"nncourseseries_description",
									"nncourseseries_summary"],
					"filters":[[{
							field: "nncourseseries_id",
							operation: "=",
							value: params.nncourseseries_id
						}]]
				};


				webappsAPI.loadData("nncourseseries", apiparam, token).then(dataresponse => {
					if (dataresponse.hasOwnProperty("data")) {
						if (dataresponse.data.length > 0) {
							const seriesobj = dataresponse.data[0];

							if (seriesobj.hasOwnProperty("onsbranch_id")) {
								if (seriesobj.onsbranch_id !== null) {
									params.onsbranch_id = seriesobj.onsbranch_id;
								}
							}
							if (seriesobj.hasOwnProperty("nncourseseries_name")) {
								if (seriesobj.nncourseseries_name !== null) {
									params.nncourse_seriesname = seriesobj.nncourseseries_name;
								}
							}
							if (seriesobj.hasOwnProperty("nncourseseries_description")) {
								if (seriesobj.nncourseseries_description !== null) {
									params.nncourse_description = seriesobj.nncourseseries_description;
								}
							}
							if (seriesobj.hasOwnProperty("nncourseseries_summary")) {
								if (seriesobj.nncourseseries_summary !== null) {
									params.nncourse_summary = seriesobj.nncourseseries_summary;
								}
							}
						}

						webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
							if (response.status === "OK") {
								negosyonowAPI.activateEntity(response.documentid,token, "nncourse").then(nnactivateresponse => {
									callback(response);
								});
							} else {
								callback(response);
							}
						});
						return;
					} // if fields
					callback({"status":"Error", "message":"Unable to load series data"});

				});
				return;
			} // needs to load series
		} // if new document
		// Fallthrough Existing document/No series information
		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				negosyonowAPI.buildEntityId(response.documentid,token, "course").then(nnresponse => {
					callback(response);
				});
			} else {
				callback(response);
			}
		});

		//callback({"status":"Error"}); return;
	}

	function customEditFieldInfo(inputlist, idx)
	{
		if (inputlist[idx].field !== "nncourse_url") {
			return inputlist[idx];
		}
		const haspage = parseInt(inputlist[idx-1].value);
		if (haspage === 1) {
			return inputlist[idx];
		}
		var tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
		tmpfield.value = "Not yet available";
		tmpfield.input = "textbox";
		return tmpfield;
	}

	function subformValidateContent(itemsubform)
	{
		var rowidx = 0;
		var colidx = 0;

		var contentvideo = "";
		var contentfile = "";
		var contentthumb = "";

		while (rowidx < itemsubform.length) {
			colidx = 0;
			contentfile = "";
			contentvideo = "";
			contentthumb = "";
			while (colidx < itemsubform[rowidx].length) {
				if (itemsubform[rowidx][colidx].field === "nncoursecontent_url") {
					contentfile = ""+itemsubform[rowidx][colidx].value;
				} else if (itemsubform[rowidx][colidx].field === "nncoursecontent_videourl") {
					contentvideo = ""+itemsubform[rowidx][colidx].value;
				} else if (itemsubform[rowidx][colidx].field === "nncoursecontent_thumbnail") {
					contentthumb = ""+itemsubform[rowidx][colidx].value;
				}
				colidx++;
			}
			console.log(contentthumb, contentfile, contentvideo)
			if (contentvideo.length > 0 && contentfile.length > 0) {
				return {"status":"Error", "message":"Cannot be both Video and File/Image at row "+(rowidx+1)};
			} else if (contentvideo.length < 1 && contentfile.length < 1 && contentthumb.length > 0) {
				return {"status":"Error", "message":"Headings cannot have thumbnails; see row "+(rowidx+1)};
			}
			rowidx++;
		}
		return {"status":"OK"};
	}

	function customValidateValues(mainform, subformlist, footerformlist)
	{
		var regfee = 0;
		var paymentdetails = "";
		var idx = 0;
		while (idx < mainform.length) {
			if (mainform[idx].field === "nncourse_regfee") {
				regfee = parseFloat(mainform[idx].value);
			} else if (mainform[idx].field === "nncourse_paymentinstructions") {
				paymentdetails = mainform[idx].value;
			}
			idx++;
		}

		if (regfee > 0 && paymentdetails.length < 1) {
			return {"status":"Error", "message":"Please provide payment instructions"};
		}
		var tmpres = {};
		idx = 0;
		while (idx < editSubFormFields.length) {
			if (editSubFormFields[idx].table === "nncoursecontent") {
				if (subformlist.length > idx) {
					tmpres = subformValidateContent(subformlist[idx]);
					if (tmpres.status !== "OK") {
						return tmpres;
					}

				}
			}
			idx++;
		}

		return {"status":"OK"};
	}

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappsForm
				location={location}
				pagetitle={pagetitle}
				allowadd={allowadd}
				allowdelete={allowdelete}
				mobilerowtitlefield={["nncourse_name"]}
				searchFields={searchFields}
				editFields={[formFields[0].concat(customeditformfields)]}
				newformFields={[newFormFields[0].concat(customnewformfields)]}
				editSubFormFields={editSubFormFields}
				newSubformFields={newSubformFields}
				customSubmit={customSubmit}
				customEditFieldInfo={customEditFieldInfo}
				customValidateValues={customValidateValues}
				userSearchParam={{"orderby":"nncourse_name"}}
				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default CourseForm;
